.custom-navbar {
    background: linear-gradient(to right, #0066ff, #00ccff);
    /* Example gradient */
}

:root {
    --primary-color: #a0b9cb;
    --secondary-color: #1f515e;
}

.custom-navbar {
    background: var(--primary-color);
}

.custom-navbar .navbar-nav .nav-link {
    color: var(--secondary-color);
}

.custom-navbar .navbar-nav .nav-link:hover {
    color: #1295b6; /* Slightly lighter color on hover */
  }